export const whistleblowingAccordionContent = [
  {
    header: "whistleblower.accordion-1-title",
    content: "whistleblower.accordion-1-desc",
    contentDetail: [],
  },
  {
    header: "whistleblower.accordion-2-title",
    content: "whistleblower.accordion-2-desc",
    contentDetail: [
      "whistleblower.accordion-2-detail-1",
      "whistleblower.accordion-2-detail-2",
      "whistleblower.accordion-2-detail-3",
      "whistleblower.accordion-2-detail-4",
    ],
  },
  {
    header: "whistleblower.accordion-3-title",
    content: "whistleblower.accordion-3-desc",
    contentDetail: [],
  },
  {
    header: "whistleblower.accordion-4-title",
    content: "whistleblower.accordion-4-desc",
    contentDetail: [
      "whistleblower.accordion-4-detail-1",
      "whistleblower.accordion-4-detail-2",
      "whistleblower.accordion-4-detail-3",
      "whistleblower.accordion-4-detail-4",
      "whistleblower.accordion-4-detail-5",
      "whistleblower.accordion-4-detail-6",
    ],
  },
]
